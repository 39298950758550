<template>
    <div class="card">
        <cardHeader title="3 Attendees" class="blue"> </cardHeader>
<div>
 <ul>

     <li v-bind:class="status_one"> <img src="../../assets/icons/chat/nobody.svg"> Guest</li>
     <li v-bind:class="status_two"> <img src="../../assets/icons/chat/lucutz1.png"> <span>M. Stefan</span></li>
     <li v-bind:class="status_three"> <img src="../../assets/icons/chat/lucutz2.png"><span>Ralf</span></li>
 </ul>

</div>
    </div>
</template>

<script>
    import cardHeader from "./cardHeader";
    export default {
        name: "atendeesCard",
        components: {cardHeader},
        data() {
            return {
                timer: '',
                status_one:'offline',
                status_two:'online',
                status_three:'offline'
            }
        },
        created () {
            this.checkUsers();
            this.timer = setInterval(this.checkUsers, 10000)
        },
        methods: {
            checkUsers () {
                this.status_one=(Math.random() >= 0.5?"offline":"online")
                this.status_two=(Math.random() >= 0.5?"offline":"online")
                this.status_three=(Math.random() >= 0.5?"offline":"online")
            }

        },
    }
</script>

<style scoped>
    .card ul,li {
        list-style:none;
        padding:0;
        margin:0;
    }
    .card li {
        box-sizing: border-box;
        height: 39.5px;
        width: 241px;
        margin: 0;
        border-bottom: 1px solid #D7D7D7;
        color: #646464;
        font-family: "Basier Square";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 9px;
    }
    .card li:hover{
        background-color:#ECF4FE;
        cursor:pointer;
    }
    .online {
        background: url("../../assets/icons/chat/online.svg")  no-repeat 92.5% center ;
    }
    .offline {
        background: url("../../assets/icons/chat/offline.svg")  no-repeat 92.5% center ;
    }
    .card img{
margin:0 10px;
        width:24px;
        height:24px;
transform:translateY(9px);
    }

</style>