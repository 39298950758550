import Vue from "vue";
import VueRouter from "vue-router";
import CoBrowsingSharing from "../views/coBrowsingSharing"
import WebinarPlanning from "../views/WebinarPlanning.vue";
import asAClient from "@/views/asAClient";
import asAClientWebcam from "@/views/asAClientWebcam";
import clientWaitingRoomVideo from "@/views/clientWaitingRoomVideo";
import ClientLivePresentation from "@/views/ClientLivePresentation";
import ClientLivePresentationOverlay from "@/views/ClientLivePresentationOverlay";
import clientPreviewFeed from "@/views/clientPreviewFeed";
import clientPreviewFeedOverlay from "@/views/clientPreviewFeedOverlay";
import SmallClientChatPreviewFeed from "@/views/SmallClientChatPreviewFeed";
import SmallClientChatPreviewWeb from "@/views/SmallClientChatPreviewWeb"
import ClientPOSTlivepresentation from "@/views/ClientPOSTlivepresentation";
import ddd from "@/views/ddd";
Vue.use(VueRouter);

const routes = [
  {
    path: "/Co-Browsing-Sharing",
    name: "Co-Browsing-Sharing",
    component: CoBrowsingSharing,

  },
  {
    path: "/webinar-planning",
    name: "Webinar-planning",
    component: WebinarPlanning,
  },
  {
    path: "/as-a-client",
    name: "As-A-Client",
    component: asAClient,
  },
  {
    path: "/as-a-client-webcam",
    name: "As-A-Client-webcam",
    component: asAClientWebcam,
  }
  ,
  {
    path: "/client-Waiting-Room-Video",
    name: "client-Waiting-Room-Video",
    component: clientWaitingRoomVideo,
  }
  ,
  {
    path: "/Client-live-presentation",
    name: "client-live-presentation",
    component: ClientLivePresentation,
  }
  ,
  {
    path: "/Client-live-presentation-overlay",
    name: "client-live-presentation-overlay",
    component: ClientLivePresentationOverlay,
  }
  ,
  {
    path: "/Client-preview-feed",
    name: "client-preview-feed",
    component: clientPreviewFeed,
  }
,
  {
    path: "/Client-preview-feed-overlay",
    name: "client-preview-feed-overlay",
    component: clientPreviewFeedOverlay,
  },
  {
    path: "/Small-Client-Chat-Preview-Feed",
    name: "Small-Client-Chat-Preview-Feed",
    component: SmallClientChatPreviewFeed,
  }
  ,
  {
    path: "/Small-Client-Chat-Preview-Web",
    name: "Small-Client-Chat-Preview-Web",
    component: SmallClientChatPreviewWeb,
  }
  ,
  {
    path: "/Client-POST-live-presentation",
    name: "Client-POST-live-presentation",
    component: ClientPOSTlivepresentation,
  }
  ,
  {
    path: "/ddd",
    name: "ddd",
    component: ddd,
  }


];

const router = new VueRouter({
  mode: "history",
  routes,

});

export default router;
