<template>
    <div class="card" :class="myclass">
        <div v-on:click="maximize">


        <cardHeader title="Webinar notes"  maximizable="true"  class="blue" style="cursor: pointer"> </cardHeader>
        </div>

        <select class="car" id="cars" v-model="current_car">
            <option value="Car models">Car models</option>
            <option value="">Remove Overlay</option>

            <option value="car1">Palisade</option>
            <option value="car2">Elantra</option>
            <option value="car3">Kona</option>
            <option value="car4">Kona2</option>
        </select>
        <textarea placeholder="Type a note..." v-model="current_car_text">

        </textarea>
        <button class="publishNote" v-on:click="publish_note"></button>
        <div class="wysiwyg">
<img src="../../assets/icons/wysiwyg/bold.svg"/>
            <img src="../../assets/icons/wysiwyg/italic.svg"/>
            <img src="../../assets/icons/wysiwyg/underline.svg"/>
            <img src="../../assets/icons/wysiwyg/draw.svg"/>
            <img src="../../assets/icons/wysiwyg/link.svg"/>
            <img src="../../assets/icons/wysiwyg/lines.svg"/>
        </div>
    </div>
</template>

<script>
    import cardHeader from "./cardHeader";

    export default {
        name: "webinarNotesCard",
        components: {cardHeader},
        data() {
            return {
                current_car:'Car models',
                myclass:'',
                current_car_text:'- 484 km reach\n' +
                    '- 80% charged in less than 50 Minutes\n' +
                    '- 8 years warranty\n',
                current_car_html:'<ul><li> 484 km reach</li>\n' +
                    '<li> 80% charged in less than 50 Minutes</li>\n' +
                    '<li> 8 years warranty</li></ul>\n',
                options: [
                    {
                        title: "Visa",
                        cardImage: "../assets/icons/lucutz_hover.png"
                    },
                    {
                        title: "Mastercard",
                        cardImage: "../assets/icons/lucutz_hover.png"
                    }
                ]


        }
        },
        methods:{
            publish_note(){


                this.$emit("change_car",this.current_car,(this.current_car=='Car models' || this.current_car==''?'':   this.current_car_text.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/-/gi,"&#10147;")));


            },
            maximize:function () {

                this.myclass=(this.myclass=="maximized"?"":"maximized");

                var the_iframe=document.getElementById('the_iframe');
                the_iframe.style.display=(this.myclass=='maximized'?'none':'')

            }
        }
    }
</script>

<style scoped>
    .publishNote {
        margin: 0 auto;
position: absolute;

        display: inline;
        color: white;
        order: 1;
        padding: .5rem;
        transition: opacity .25s ease-out;
        width: 40px;

        background:url("../../assets/icons/notes/publish.svg") no-repeat center;
        cursor:pointer;
        height: 40px;
        transform: translate(190px,-40px);
        background-color: #00AAD2;
border-radius: 20px;
        border:0px;
       }
    .wysiwyg{
        margin-top: 20px;
        box-sizing: border-box;
        height: 31px;
        min-width: 241px;
        border: 1px solid #D6D6D6;
padding-left:19.5px;
padding-top:9px;
        border-radius:  0 0 12px 12px;



        background-color: #ECF4FE;

    }
    .wysiwyg img{
        opacity: 0.4;
        margin-left:12.5px
    }
    .wysiwyg img:hover{
        opacity: 1;
        cursor:pointer;
        margin-left:12.5px
    }
    .maximized {
        position: absolute;
        top:30px;
        margin:0 265px 100px;
        width: 740px!important;
        height: 600px;

    }
    .maximized .cardHeader{
        width: 722px;
    }
    .maximized textarea{
        height: 458px;
        width: 700px;
    }
    .maximized select{
        width: 97%;
        background:  url("../../assets/icons/dropdownarrow.svg") 98.5% center no-repeat,  url("../../assets/icons/car.svg") 1% center no-repeat;

    }
    .maximized .publishNote{
        transform: translate(690px,-40px);
    }

</style>