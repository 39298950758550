<template>
    <div class="card sticky" :class="myclass" >
        <div v-on:click="chatClicked">

    <cardHeader title="Chat (all)" :class="header_class" maximizable="true" > </cardHeader>
        </div>
        <div class="messages" id='messageslist'>
            <div class="message"  v-for="message in messages" :key="message.message">

                <div class="from">{{message.from}}</div>
                <div class="time" >{{message.time}}</div>
                <div class="text" :class="(message.from=='Marcus'?'sent':'received')">

                    <p >{{message.text}}</p></div>

            </div>
        </div>
    <div class="send_messages">
<input type="text" v-model="message" placeholder="Type a message…"/>
        <button class="attach"></button>
        <button class="send" v-on:click="send_message()"></button>
    </div>
    </div>
</template>

<script>
    import cardHeader from "./cardHeader";

    export default {
        name: "videocard",
        components: {cardHeader},
        data() {
            return {
                message:"",
                myclass:'show',
                header_class:'green',
                messages:[
                    {time:"10:24",
                    text:"bla bla",
                    from:"Ralph"},
                    {time:"10:25",
                        text:"bla bla much ?",
                        from:"Marcus"}

                ],
                bot_messages:[
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?"
                ]
            }
        },
        methods:{
            chatClicked:function () {
this.myclass=(this.myclass=='hide'?'quickshow':(this.myclass=='quickshow'?"maximized":"quickshow"));
//this.header_class=(this.header_class==

                     var the_iframe=document.getElementById('the_iframe');
                    the_iframe.style.display=(this.myclass=='maximized'?'none':'')

                this.header_class='green'


            },
            send_message:function () {
                this.messages.push({time:"10:24",
                    text:this.message,
                    from:"Marcus"})
                this.message="";
                setTimeout(()=>{
                    this.messages.push({time:"10:24",
                        text:this.bot_messages[Math.floor((Math.random()*10) + 1)],
                        from:"Ralph"})
                },(Math.random()*1000+2000));


            }
        },
        updated(){

            var objDiv = document.getElementById("messageslist");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }
</script>

<style scoped>

    .sticky {
        position:fixed;

        width:auto;



        transform: translateY(355px);
        bottom:0px;
    }
    .show{
            animation: moveup 1s;
            transform: translateY(20px);
        }
    .quickshow{

        transform: translateY(20px);
    }

    .hide{
        animation: movedown 1s;
        transform: translateY(355px);
    }


    @keyframes moveup {
        0% {
            transform: translateY(355px);
        }

        100% {
            transform: translateY(20px);
        }
    }
    @keyframes movedown {
        0% {
            transform: translateY(20px);
        }

        100% {
            transform: translateY(355px);
        }
    }
.messages{
max-width: 239px;
    height:280px;
    overflow-y: auto;
    overflow-x: hidden;
}
    .send_messages{
        height:56px;
        width:100%;
        background-color: #F8F8F8;
        border-top: 1px solid #DCDCDC;
    }

    .send {
        margin: 0 auto;



        display: inline;
        color: white;
        order: 1;

        transition: opacity .25s ease-out;
        width: 24px;
        height: 24px;
        border:0px;

        background:url("../../assets/icons/chat/send.svg") no-repeat center;
        transform: translate(-30px,14px);
        position: absolute;
    }
    .attach {
        position: absolute;
        transform: translate(-55px,14px);
        display: inline;
        color: white;
        order: 1;

        transition: opacity .25s ease-out;
        width: 24px;
        height: 24px;
        border:0px;
        margin: 0 auto;
        background:url("../../assets/icons/chat/attach.svg") no-repeat center;
    }
    .from{
        padding-left:18px;
float:left;
        font-size: 8px;
        color: #989898;
        letter-spacing: 0;
        line-height: 22px;
        height:20px;
        width:30%;
    }

  .sent p{
      margin-left: 10px;
      line-height: 30px;

    }
    .received p{
        margin-left: 22px;
        line-height: 30px;

    }
    .time{
        line-height: 22px;
        padding-right:18px;
float:left;
        text-align: right;
        font-size: 8px;
        color: #989898;
        letter-spacing: 0;

        height:20px;
        width:50%;
    }

    .text{
float: left;
width: 100%;
        padding-left: 20px;
        font-size: 10px;
        color: #000000;
        letter-spacing: 0;
        line-height: 16px;


    }

    .received{
        height: 50px;
        background: url("../../assets/icons/chat/reply_arrow.svg") no-repeat 82%  center,url("../../assets/icons/chat/message_received.svg") no-repeat 50%,url("../../assets/icons/chat/lucutzmic1.png") no-repeat 2%  bottom;

margin-right:10px;

    }
    .sent{
        padding: 0;
        height: 60px;
margin-left:10px;
        background:url("../../assets/icons/chat/message_sent.svg") no-repeat left,url("../../assets/icons/chat/lucutzmic2.png") no-repeat 93%  bottom  ;

    }
    .message {
     }

    .maximized {
        position: absolute;
        top:30px;
        margin:0 265px 100px;
        width: 740px;
        height: 800px;
        transform:translate(-1032px,86px)
    }
    .maximized .cardHeader{
        width: 722px;
    }
    .maximized .messages{
        height:713px;
        max-width: none;

    }
    .maximized .send_messages{
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .maximized .send_messages input{
        width:700px;
    }


    .maximized .received{
        height: 50px;
        background: url("../../assets/icons/chat/reply_arrow.svg") no-repeat 92%  center,url("../../assets/icons/chat/message_reveived_big.svg") no-repeat 50%,url("../../assets/icons/chat/lucutzmic1.png") no-repeat 2%  bottom;

        margin-right:10px;

    }
    .maximized  .sent{
        padding: 0;
        height: 60px;
        margin-left:10px;
        background:url("../../assets/icons/chat/message_sent_big.svg") no-repeat left,url("../../assets/icons/chat/lucutzmic2.png") no-repeat 93%  bottom  ;

    }

</style>