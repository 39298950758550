<template>
  <div style="margin-top: 40px">
    <div class="layout">
      <div class="content">
        <div class="header">
          <div class="actions">
            <div class="search">
              <input type="text" placeholder="Search" />
              <img src="../assets/static/search.svg" alt="search" />
            </div>
            <div class="filterBy">
              <div class="filter">
                <span>Filter by <strong>Scheduled</strong></span>
                <img src="../assets/static/filter.svg" alt="filter" />
              </div>
            </div>
          </div>
          <div class="addNewAction">
            <div class="button">
              <button v-on:click="new_pressed = true">
                Add New Webinar
                <img src="../assets/static/add-new.svg" alt="add" />
              </button>
            </div>
          </div>
        </div>
        <div class="table">
          <table style="width: 100%; background: white">
            <thead>
              <tr class="tableHeader">
                <th>Status</th>
                <th>Title</th>
                <th>Type</th>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
                <th>Attendees</th>
                  <th>Startin in</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, i) in dataSet" :key="i">
                <td v-if="!data.edit" :class="data.status">
                  {{ data.status }}
                </td>
                <td v-if="!data.edit">{{ data.title }}</td>
                <td v-if="data.edit" colspan="2">
                  <div class="column-one">
                    <div class="row-one">
                      <select
                        v-model="data.status"
                        class="select text"
                        name="status"
                        id="status"
                      >
                        <option value="Complete">Complete</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="Canceled">Canceled</option>
                      </select>
                      <input
                        v-model="data.title"
                        class="webinar-title text"
                        type="text"
                      />
                    </div>
                    <div class="row-two">
                      <input
                        class="webinar-link text"
                        type="text"
                        value="https://www.hyundai.de/modelle/iTuscon-fastback-n/"
                      />
                    </div>
                  </div>
                </td>
                <td v-if="!data.edit">{{ data.type }}</td>
                <td v-if="!data.edit">{{ data.date }}</td>
                <td v-if="!data.edit">{{ data.start }}</td>
                <td v-if="!data.edit">{{ data.end }}</td>
                <td v-if="!data.edit">{{ data.attendees }}</td>
                <td v-if="!data.edit"> <vue-countdown-timer
                        @start_callback="alert('sss')"
                        @end_callback="alert('eee')"
                        :start-time="data.start_time"
                        :end-time="data.end_time"
                        :interval="1000"
                        :start-label="''"
                        :end-label="''"
                        label-position="begin"
                        :end-text="''"
                        :day-txt="'days '"
                        :hour-txt="'h '"
                        :minutes-txt="'m '"
                        :seconds-txt="'seconds'">
                  <template slot="start-label" slot-scope="scope">
                    <span style="color: red" v-if="scope.props.startLabel !== '' && scope.props.tips && scope.props.labelPosition === 'begin'">{{scope.props.startLabel}}:</span>
                    <span style="color: blue" v-if="scope.props.endLabel !== '' && !scope.props.tips && scope.props.labelPosition === 'begin'">{{scope.props.endLabel}}:</span>
                  </template>

                  <template slot="countdown" slot-scope="scope">
                    <span v-if="scope.props.days>0">{{scope.props.days}}</span><span v-if="scope.props.days>0">{{scope.props.dayTxt}}</span>
                    <span v-if="scope.props.days==0">{{scope.props.hours}}</span><span v-if="scope.props.days==0">{{scope.props.hourTxt}}</span>
                    <span v-if="scope.props.days==0">{{scope.props.minutes}}</span><span v-if="scope.props.days==0">{{scope.props.minutesTxt}}</span>

                  </template>

                  <template slot="end-label" slot-scope="scope">
                    <span style="color: red" v-if="scope.props.startLabel !== '' && scope.props.tips && scope.props.labelPosition === 'end'">{{scope.props.startLabel}}:</span>
                    <span style="color: blue" v-if="scope.props.endLabel !== '' && !scope.props.tips && scope.props.labelPosition === 'end'">{{scope.props.endLabel}}:</span>
                  </template>

                  <template slot="end-text" slot-scope="scope">
                    <span style="color: green">{{ scope.props.endText}}</span>
                  </template>
                </vue-countdown-timer>
                </td>
                <td v-if="data.edit" colspan="5" class="column-two">
                  <div class="row-one" style="width: 456px !important">
                    <select
                      class="select-type text"
                      name="webinar-type"
                      id="webinar-type"
                      v-model="data.type"
                    >
                      <option value="1:many">1:many</option>
                      <option value="webinar">webinar</option>
                      <option value="1:1">1:1</option>
                    </select>
                    <input
                      v-model="data.date"
                      class="edit-date text"
                      type="text"
                    />
                    <input
                      v-model="data.start"
                      class="edit-hour text"
                      type="text"
                    />
                    <input
                      v-model="data.end"
                      class="edit-hour text"
                      type="text"
                    />
                    <input
                      v-model="data.attendees"
                      class="edit-attendees text"
                      type="text"
                      value="10"
                    />
                  </div>
                  <div class="row-two" style="width: 456px !important">
                    <input
                      class="description text"
                      type="text"
                      placeholder="Description"
                    />
                  </div>
                </td>
                <td v-if="!data.edit" class="invite">
                  <div>
                    <button class="inviteButon">Invite</button>
                    <button class="goToRoom" @click="goToPresent()">
                      Presentation&nbsp;Room
                    </button>
                    <img
                      @click="data.edit = true"
                      style="cursor: pointer"
                      src="../assets/static/edit.svg"
                      alt="edit"
                      class="editImg"
                    />
                  </div>
                </td>
                <td v-if="data.edit" colspan="1">
    &nbsp;
                </td>
                <td v-if="data.edit" colspan="1">
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div class="column-three">
                      <div class="square" @click="data.edit = false">
                        <img src="../assets/static/save.svg" alt="save" />
                      </div>
                      <div class="square" @click="data.edit = false">
                        <img src="../assets/static/delete.svg" alt="save" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebinarPlanning",

  data() {
    return {
      edit_pressed: false,
      edit: false,
      new_pressed: false,
      dataSet: [
        {
          status: "Scheduled",
          title: "Hyundai i20 Advantage Presentation",
          type: "webinar",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          start_time:"2020-11-10 00:00:00",
          end_time:"2020-11-10 00:00:00",
          attendees: 3,
          edit: false,
        },
        {
          status: "Scheduled",
          title: "Hyundai iTuscon Advantage Presentation",
          type: "webinar",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
        {
          status: "Complete",
          title: "Hyundai i20 Advantage Presentation",
          type: "1:many",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
        {
          status: "Complete",
          title: "Hyundai iTuscon Advantage Presentation",
          type: "webinar",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
        {
          status: "Complete",
          title: "Hyundai i20 Advantage Presentation",
          type: "1:many",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
          {
              status: "Canceled",
              title: "Hyundai iTuscon Advantage Presentation",
              type: "1:many",
              date: "17.07.2020",
              start: "17:00",
              end: "17:30",
              attendees: 3,
              edit: false,
          },
          {
              status: "Complete",
              title: "Hyundai i20 Advantage Presentation",
              type: "webinar",
              date: "17.07.2020",
              start: "17:00",
              end: "17:30",
              attendees: 3,
              edit: false,
          },
          {
              status: "Scheduled",
              title: "Hyundai i20 Advantage Presentation",
              type: "webinar",
              date: "17.07.2020",
              start: "17:00",
              end: "17:30",
              attendees: 3,
              edit: false,
          },
        {
          status: "Canceled",
          title: "Hyundai iTuscon Advantage Presentation",
          type: "1:many",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
        {
          status: "Complete",
          title: "Hyundai i20 Advantage Presentation",
          type: "webinar",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
        {
          status: "Scheduled",
          title: "Hyundai i20 Advantage Presentation",
          type: "webinar",
          date: "17.07.2020",
          start: "17:00",
          end: "17:30",
          attendees: 3,
          edit: false,
        },
      ],
    };
  },
  created(){



    this.dataSet.forEach(function (element,i) {

      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate()+Math.floor(Math.random() * (5 ) ));

      let month =dateObj.getMonth()+1;
      let day = String(dateObj.getDate()).padStart(2, '0');
      let year = dateObj.getFullYear();
      let azi = year+"-"+month  + '-'+ day;
      let azi2 = day+"."+month  + '.'+ year;
      let hour=   Math.floor(Math.random() * (18 - 10) + 10);
      hour=7;
      let minutes_array=[0,30]

      let minutes=minutes_array[Math.round(Math.random())];
minutes=String(minutes).padStart(2, '0')

      element.start_time=azi+" "+(hour+i)+":"+minutes+":00"
       element.start=(hour+i)+":"+(minutes)
      if ((parseInt(minutes)+30)==60) {
        minutes='00'
        hour=hour+1
      }else{
        minutes=30
      }
      element.end_time=azi+" "+(hour+i)+":"+minutes+":00"
element.date=azi2
      element.end=(hour+i)+":"+(minutes)
      element.attendees= Math.floor(Math.random() * (8 - 1) + 1);
     })
console.log(this.dataSet)
  },
  methods: {
    goToPresent() {
      this.$router.push({ name: "Co-Browsing-Sharing" });
    },
  },
};
</script>

<style scoped>
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.content {
  width: 1300px;
  display: flex;
  justify-content: center;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 26px;
}
.actions {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.search {
  width: 320px;
  height: 30px;
  position: relative;
}
.search input {
  box-sizing: border-box;
  height: 30px;
  width: 320px;
  border: 1px solid #dadada;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  outline: none;
  padding-left: 15px;
}
.search input::placeholder {
  color: #000000;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}
.search img {
  width: 15px;
  position: absolute;
  right: 10px;
  top: 7px;
}

.filterBy {
  box-sizing: border-box;
  height: 30px;
  width: 200px;
  border: 1px solid #dadada;
  border-radius: 20px;
  background-color: #f6f3f2;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.filter img {
  position: absolute;
  top: 8px;
  right: 10px;
}

.filter span {
  margin-left: 20px;
}

.addNewAction {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.addNewAction button {
  height: 30px;
  width: 160px;
  border-radius: 20px;
  background-color: #8bd121;
  border: 0;
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  outline: none;
  cursor: pointer;
  margin-right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
}

table {
  border-collapse: separate !important;

  width: 100%;
  margin-top: 20px;
  border: 0;
  border-radius: 10px;
}
table tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

table thead tr th:first-child {
  padding-left: 0.25cm; /* To align with section title */
  border-bottom: 0.03cm solid #8a8a8a;
  border-radius: 15px 0px 0px 0px;
}

table thead tr th:last-child {
  padding-left: 0.25cm; /* To align with section title */
  border-bottom: 0.03cm solid #8a8a8a;
  border-radius: 0px 15px 0px 0px;
}

.tableHeader {
  height: 31px;
  width: 1089px;
  border: 1px solid #002b5f;
  border-radius: 12px;
  background-color: #002b5f;
}
th {
  text-align: left;
  padding: 8px;
  color: #ffffff;

  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12px;
}

tr {
  height: 40px;
}
.editImg {
  visibility: hidden;
}
tr:hover {
  background: #ecf4fe;
}

tr:hover .editImg {
  visibility: visible;
}

td {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;

  font-size: 14px;

  color: #646464;
}

.Scheduled {
  color: #00aad2;
  font-weight: 800;
}
.Complete {
  color: #8bd121;
  font-weight: 800;
}
.Canceled {
  color: #e63313;
  font-weight: 800;
}
.invite div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.inviteButon {
  color: #363636;

  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  height: 24px;
  width: 70px;
  border-radius: 2px;
  background-color: rgba(152, 152, 152, 0.4);
  border: 0;
}
.goToRoom {
  height: 24px;
  width: 170px;
  border-radius: 2px;
  background-color: rgba(139, 209, 33, 0.4);
  color: #002b5f;

  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  border: 0;
}

.select {
  box-sizing: border-box;
  height: 31px;
  width: 111px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}

.webinar-title {
  box-sizing: border-box;
  width: 289px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}

.webinar-link {
  box-sizing: border-box;
  width: 411px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}

td .text {
  color: #646464;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.select-type {
  box-sizing: border-box;
  width: 81px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}
.edit-date {
  box-sizing: border-box;
  width: 91px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}
.edit-hour {
  box-sizing: border-box;
  width: 76px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}
.edit-attendees {
  box-sizing: border-box;
  width: 53px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}
.description {
  box-sizing: border-box;
  width: 456px;
  height: 31px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
}
.square {
  height: 24px;
  width: 24px;
  opacity: 0.4;
  border-radius: 2px;
  background-color: #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.edit {
  height: 100px;
}

.column-one {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row-one {
  width: 411px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.column-three {
  height: 30%;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.tableHeader:hover {
  background: #002b5f !important;
}

button {
    cursor: pointer;
}
</style>
