<template>
    <div class="footer">

    </div>
</template>

<script>
    export default {
        name: "myfooter"
    }
</script>

<style scoped>
    .footer{
        width: 100vw;
        float: left;
    }
</style>