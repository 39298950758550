<template>
<div class="burger_menu">
        <img class="burger_menu_button" src="../../assets/icons/burger.svg" v-on:click="show_menu">
        <div :class="myclass" >
                <ul>
                        <li v-on:click="   $router.replace({ name: 'Co-Browsing-Sharing' });hide_menu();">As A Presenter</li>
                        <li v-on:click="   $router.replace({ name: 'Webinar-planning' });hide_menu();">Webinar Scheduling</li>


                        <li v-on:click="   $router.replace({ name: 'client-Waiting-Room-Video' });hide_menu();">Client waiting room
                        </li>
                        <li v-on:click="   $router.replace({ name: 'client-live-presentation' });hide_menu();">Client live presentation (Cam)
                        </li>
                        <li v-on:click="   $router.replace({ name: 'client-live-presentation-overlay' });hide_menu();">Client live presentation (Overlay)
                        </li>

                        <li v-on:click="   $router.replace({ name: 'client-preview-feed' });hide_menu();">Client preview (video) </li>
                        <li v-on:click="   $router.replace({ name: 'client-preview-feed-overlay' });hide_menu();">Client preview (video with overlay) </li>
                        <li v-on:click="   $router.replace({ name: 'Small-Client-Chat-Preview-Web' });hide_menu();">Small Client chat preview (cam) </li>
                        <li v-on:click="   $router.replace({ name: 'Small-Client-Chat-Preview-Feed' });hide_menu();">Small Client chat preview (video)  </li>
                        <li v-on:click="   $router.replace({ name: 'Client-POST-live-presentation' });hide_menu();">Client POST live presentation   </li>
                        <li v-on:click="   $router.replace({ name: 'ddd' });hide_menu();">DDD   </li>

                        <!--<li v-on:click="hide_menu">Close menu</li>-->

                </ul>
        </div>

</div>
</template>

<script>
    export default {
        name: "burgermenu"
            , data() {
                    return {
                            myclass:'hidden'
                    }
            },
            methods:{
                    show_menu:function () {
                            this.myclass=(this.myclass=="hidden_menu"?"hidden":"hidden_menu")
                    },
                    hide_menu:function () {
                            this.myclass="hidden"
                    }
            }
    }
</script>

<style scoped>
       .burger_menu{
       float: left;
               width:50px;
       }
.burger_menu_button {

        margin: 12px;
        cursor: pointer;
}
.hidden_menu{
        background: white;
        border: 1px solid #D7D7D7;
        border-top: 0;
        transition: all 0.5s ease-in-out;
        transform-origin: left top;
        transform: scaleY(1);
        border-bottom-right-radius: 30px;
}


       .hidden_menu ul{
        list-style: none;
        padding: 20px;
        margin: 0;

}
       .hidden{

               transform: scaleY(0);

       }
.hidden_menu li{
        box-sizing: border-box;
        height: 22px;
        width: 270px;
        margin: 10px;
        border-bottom: 1px solid #D7D7D7;
        color: #646464;

        font-size: 14px;
        letter-spacing: 0;
        line-height: 9px;
        cursor: pointer;
}
.hidden_menu{
position: fixed;
        top:40px;
        left:00px;
}
</style>
