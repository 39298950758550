<template>
    <div class="header" >
        <div class="left_side">
<burgermenu/>
<logo/>


        </div>

        <div class="right_side">

            <div class="username"> Marcus Mueler</div>
        <div class="user_photo" />
        <img class="help" src="../../assets/icons/question.svg">
        <img class="logout"  src="../../assets/icons/logout.svg">
        </div>

    </div>
</template>

<script>

    import burgermenu from './burgerMenu'
    import  logo from "./logo"

    export default {
        name: "myheader",
        components: {
            burgermenu,
            logo
        }
    }
</script>

<style scoped>
.header{
    position:fixed;
    width: 100vw;
    background: #FFFFFF;
    border-bottom: 1px solid #E7E7E7;
height: 40px;
    top:0;
    z-index: 2;
}
    .username
    {

        font-size: 12px;
        color: #646464;
        letter-spacing: 0;
        text-align: right;
        line-height: 25px;
        margin-top: 8px;
    float: left;
    }
    .left_side{

        float: left;
height:20px;
    }
    .right_side{
        margin: 0;

        min-width: 20px;
        height: 40px;
        float: right;
    }
    .user_photo{
        float: left;
        margin:  8px 17px;
        width: 24px;
        height: 24px;
        background: url("../../assets/icons/lucutz.png") ;
        background-size: contain;
    }
.user_photo:hover{
    background: url("../../assets/icons/lucutz_hover.png") ;
    background-size: contain;
}
.help{
    float: left;
    padding: 12px 17px;
    border-left: 1px solid #E7E7E7;
}
.logout{
    border-left: 1px solid #E7E7E7;
    float: left;
    padding: 12px  17px;
    padding-right: 25px;
}
</style>