<template>
  <div class="card" :class="myclass" style="max-height: 380px">
    <div v-on:click="maximize" style="cursor: pointer;">
      <cardHeader title="Audio & video stream" class="blue" maximizable="true">
      </cardHeader>
    </div>
    <figure>
      <vue-web-cam
        v-show="iswebcam"
        ref="webcam"
        :device-id="deviceId"
        width="100%"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @cameras="onCameras"
        @camera-change="onCameraChange"
      />
      <video
        ref="video"
        id="video"
        v-show="!iswebcam"
        src="https://hyundai.stonesoupdev.com/film2.mp4"
        v-on:play="progressLoop()"
      ></video>
      <div
        class="camera_is_off"
        :class="car_overlay"
        v-show="video_paused != 'play_button pause_button'"

      >
          <transition name="bounce">

          <p v-if="current_car_text!=''" class="overlay_text" v-html="current_car_text">


          </p>
          </transition>
      </div>
    </figure>
<div class="controls">
    <select
      class="video"
      ref="video_stream"
      id="video_stream"
      v-model="selected_stream"
    >
      <option value="0">Video Stream selector</option>

      <option value="1">Kona Presentation</option>
      <option value="2">Kona Discounts </option>
      <option
        v-for="device in devices"
        :key="device.deviceId"
        :value="device.deviceId"
      >
        {{ device.label }}
      </option>
    </select>
    <select class="audio">
      <option>Audio Stream selector</option>
      <option>Microsoft Lifechat 3000 XL</option>
      <option>Behringer 54LX</option>
    </select>
    <figcaption>
      <img
        src="../../assets/icons/progressbar.svg"
        style="margin-top: 10px; margin-bottom: 10px"
      />
      <progress
        id="progress"
        ref="progress"
        max="100"
        style="display: none"
        value="0"
      >
        Progress
      </progress>
    </figcaption>

    <div class="time" ref="timer" role="timer">00:00:00</div>
    <button
      ref="play"
      v-bind:class="video_paused"
      aria-label="Play"
      role="button"
      v-on:click="playPause()"
    ></button>
</div>
  </div>
</template>

<script>
import Vue from "vue";

import cardHeader from "./cardHeader";
import { WebCam } from "vue-web-cam";
Vue.use(WebCam);

export default {
  name: "videoCard",
  components: { cardHeader, "vue-web-cam": WebCam },
    props: {
        car_overlay: String,
        current_car_text:String,
    },
  data() {
    return {
      myclass: "",
      selected_stream: 0,
      video_paused: "play_button",
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
    };
  },
  computed: {
    iswebcam: function () {
      return this.selected_stream.length > 3;
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    selected_stream: function () {
      const video = this.$refs.video;

      video.pause();
      video.src =
        "http://hyundai.stonesoupdev.com/film" + this.selected_stream + ".mp4";

      video.load();
        video.play();
      //this.video_paused = "play_button";
    },
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      // eslint-disable-next-line no-unused-vars
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    maximize: function () {
      this.myclass = this.myclass == "maximized" ? "" : "maximized";

      var the_iframe = document.getElementById("the_iframe");
      the_iframe.style.display = this.myclass == "maximized" ? "none" : "";
    },
    progressLoop: function () {
      const video = this.$refs.video;
      const progress = this.$refs.progress;
      const timer = this.$refs.timer;
      setInterval(function () {
        progress.value = Math.round((video.currentTime / video.duration) * 100);
        var date = new Date(1970, 0, 1);
        date.setSeconds(video.currentTime);
        timer.innerHTML = date
          .toTimeString()
          .replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
      });
    },
    onCapture() {
      this.img = this.$refs.webcam.capture();
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
    playPause: function () {
      if (this.$refs.video_stream.value.length > 3) {
        this.$refs.webcam.start();

        if (this.video_paused === "play_button") {
          this.video_paused = "play_button pause_button";
        } else {
          this.video_paused = "play_button";
        }

        return;
      }

      const video = this.$refs.video;

      if (video.paused) {
        video.play();

        this.video_paused = "play_button pause_button";
      } else {
        video.pause();
        this.video_paused = "play_button";
      }
    },
  },
};
</script>

<style scoped>
figure {
  background: #002b5f;
  padding: 10px;
  margin: 0;
  padding-top: 0;
  width: 220px;
}

video {
  display: block;
  width: 100%;
  height: 160px;
  object-fit: cover;
}

figcaption {
  align-items: center;
  border: 1px solid #d7d7d7;
  margin: 10px;
  margin-top: 0px;
  width: 200px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 200px auto min(115px);
  padding: 0 9px;
}

.play_button {
  margin: 0 auto;

  background: #e52e71;
  display: inline;
  color: white;
  order: 1;
  padding: 0.5rem;
  transition: opacity 0.25s ease-out;
  width: 62px;

  background: url("../../assets/icons/play.svg") no-repeat;
  cursor: pointer;
  height: 62px;
  transform: translate(90px, 10px);

  border: none;
}
.pause_button {
  background: url("../../assets/icons/pause.svg") no-repeat;
}

.video {
  background: url("../../assets/icons/video.svg") center no-repeat,
    url("../../assets/icons/dropdownarrow.svg") center no-repeat;
  background-position: 2.5%, 94.5%;
}

.audio {
  background: url("../../assets/icons/audio.svg") center no-repeat,
    url("../../assets/icons/dropdownarrow.svg") center no-repeat;
  background-position: 2.5%, 94.5%;
  margin-bottom: 0;
  border-bottom: 0;
}
.car {
  background: url("../../assets/icons/car.svg") center no-repeat,
    url("../../assets/icons/dropdownarrow.svg") center no-repeat;
  background-position: 2.5%, 94.5%;
}
button:hover {
  cursor: pointer;
  opacity: 0.8;
}
progress {
  margin-top: 10px;
  margin-bottom: 10px;
}
.time {
  min-height: 16px;
  min-width: 65px;
  color: #646464;
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

/* Fallback stuff */
progress[value] {
  appearance: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
  color: dodgerblue;
  display: inline;
  height: 15px;
  order: 1;
  position: relative;
  width: 100%;
}

/* WebKit styles */
progress[value]::-webkit-progress-bar {
  background-color: whiteSmoke;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-image: linear-gradient(to right, #35c567, #35c567);
  border-radius: 3px;
  position: relative;
  transition: width 1s linear;
}

/* Firefox styles */
progress[value]::-moz-progress-bar {
  background-image: -moz-linear-gradient(to right, #35c567, #35c567);
  border-radius: 3px;
  position: relative;
  transition: width 1s linear;
}
.camera_is_off {
  background: url("../../assets/icons/camera_is_off.svg") no-repeat top left;
  background-color: black;
  height: 160px;
  width: 220px;
  position: absolute;
  transform: translateY(-160px);
}

.maximized {
  transform: translateX(260px);
width: 740px;
}
.maximized .cardHeader {
  width: 722px;
}

.maximized video {
  height: 400px;
}
.maximized figure {
  width: 721px;
}
  .maximized .camera_is_off{
    height: 400px;
    width: 721px;
    position: absolute;
    transform: translateY(-400px)
  }
  .maximized .video { }
  .maximized .audio {
      border-bottom: 1px solid #d7d7d7;
  }
.maximized .play_button {
    transform: translate(350px, 30px);
}


  .maximized figcaption{
      display: block;
      float:right;
      margin-top:2px;
  }
  .maximized .controls {
      z-index: 100;
    background: white;
border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
  }
.overlay_text{
    text-align: right;
    font-size:8px;
    padding-top:40px;
    padding-right: 31px;
}
  .maximized .overlay_text{
      font-size:20px;
      padding-top: 120px;
      padding-right: 34px;
  }
.car0{
    background:none;
}
.car1 {

    color:white;
    text-shadow: 1px 1px black;

    font-size: 20px;
    background:
            url("../../assets/cars/1.png") 100% 5px no-repeat,
            linear-gradient(to right, transparent, black) 150px 0px no-repeat
;

    display: block!important;
    background-size:40%, 571px 400px ;
}
.car2 {

    font-size: 20px;
    color:white;
    background:
            url("../../assets/cars/2.png") 100% 5px no-repeat,
            linear-gradient(to right, transparent, black)  150px 0px no-repeat
;

    display: block!important;
    background-size:45%, 571px 400px ;
    text-shadow: 1px 1px black;
}
.car3 {

    color:yellow;
    font-size: 20px;
    background:
            url("../../assets/cars/3.png") 100% 10px no-repeat,
            linear-gradient(to right, transparent, black)  150px 0px no-repeat
    ;

    display: block!important;
    background-size:40%, 571px 400px ;

    text-shadow: 1px 1px black;
}
.car4 {

    font-size: 40px;
    background:
            url("../../assets/cars/4.png") 100% 1px no-repeat,
            linear-gradient(to right, transparent, black)  150px 0px no-repeat
;


    background-size:30%, 571px 400px ;
    display: block!important;
    color: white;
    text-transform: uppercase;
    display: block!important;
    text-align: center;
    text-shadow: 1px 1px black;
}
.maximized .car1{
    background:
            url("../../assets/cars/1.png") 92% -21px no-repeat,
            linear-gradient(to right, transparent, black) 150px 0px no-repeat
;

    display: block!important;
    background-size:50%, 571px 400px
}
.maximized .car2{
    background:
            url("../../assets/cars/2.png") 92% -21px no-repeat,
            linear-gradient(to right, transparent, black 200%)   150px 0px no-repeat
;

    display: block!important;
    background-size:50%, 571px 400px ;
}
.maximized .car3{
    background:
            url("../../assets/cars/3.png") 92% 2% no-repeat,
            linear-gradient(to right, transparent, greenyellow 200%)  150px 0px no-repeat
;

    display: block!important;
    background-size:50%, 571px 400px ;
}

.maximized .car4{
    background:
            url("../../assets/cars/4.png") 88% -20px no-repeat,
            linear-gradient(to right, transparent, black) 150px 0px no-repeat
;


    background-size:40%, 571px 400px
}

.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
