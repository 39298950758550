<template>
    <div class="cardHeader">
        {{
        this.$props.title
        }}
        <img v-show=" this.$props.maximizable" class="maximize_button" src="../../assets/icons/maximize.svg">
    </div>
</template>

<script>
    export default {
        name: "cardHeader",
        props: {

            title: {
                type : String,
                default : "",
            },
            maximizable:{
                type: Boolean,
                default: false
            }
        },
    }
</script>

<style scoped>
.cardHeader {


    background: #646464;
    height: 25px;
    width: 221px;


    font-size: 11px;
margin: 0;
    padding: 0 ;
    padding-right:10px;
    padding-left:9px;
    letter-spacing: 0;
    text-align: center;

    line-height: 30px;
    color: #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;


}

.blue{
    font-size: 12px;

    background: #002B5F;

    height: 30px;
}

.green{
    font-size: 12px;

    background: #8BD121;
    height: 30px;
    cursor: pointer;
}
.teal{
    font-size: 12px;
    background: #93B0D4;
    height: 30px;
    cursor: pointer;
}

.maximize_button{
    float: right;
    margin-top: 10px;
}
.maximized .maximize_button{
    transform: rotate(180deg);
}
</style>