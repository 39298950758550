<template>
    <div class="card">

        <cardHeader title ="https://www.hyundai.de/modelle/i30-fastback-n/" style="width: 711px;"  > </cardHeader>
    <iframe  id="the_iframe" src="https://www.hyundai.de/modelle/i30-fastback-n/" height="780" width="100%" frameBorder="0">

    </iframe>

    </div>

</template>

<script>
    import cardHeader from "./cardHeader";
    export default {
        name: "iframeCard",
        components: {cardHeader}
    }
</script>

<style scoped type="text/css">
    #the_iframe
    {
       height: calc(100vh - 170px);
    }
</style>