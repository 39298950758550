<template>
    <div>
        <navigator/>
     <div class="row">

<div class="column left">
    <videoCard :car_overlay="current_car" :current_car_text="current_car_text"></videoCard>
    <webinarNotesCard style="

    margin-top: 91px;
    min-height: 340px;
    border-bottom: 0px;
    position: absolute;
  width:240px;
    bottom: 0px;
" @change_car="change_car"></webinarNotesCard>
</div>
<iframeCard class="column middle"></iframeCard>
        <div class="column right">
        <atendeesCard  ></atendeesCard>

        <chatCard  ></chatCard>
        </div>

    </div>
    </div>

</template>

<script>
    import videoCard  from "@/components/cards/videoCard";
    import chatCard from "@/components/cards/chatCard";
    import atendeesCard from "@/components/cards/atendeesCard";
    import iframeCard from "@/components/cards/iframeCard";
    import webinarNotesCard from "@/components/cards/webinarNotesCard";

    import navigator from "@/components/partials/navigator";

    export default {
        name: "CoBrowsingSharing"
    ,
        components:{videoCard,chatCard,iframeCard,atendeesCard,webinarNotesCard,navigator}
    , data() {
            return {
                current_car:"",current_car_text:""

            }},
            methods:{
                change_car:function (car_number,current_car_text) {

                   this.current_car=car_number;
                   if (this.current_car_text==current_car_text)current_car_text='';
                   this.current_car=(current_car_text!=''?car_number:0);
                   this.current_car_text=current_car_text;
                }

            }
    }
</script>

<style scoped>
    .row{

       width: 1312px;
        margin: 0 auto;
        padding: 0px;
    }
    .column {
        float: left;
        width: 33.33%;
        padding: 0;
        float: left;
    }

    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }


    .left, .right {
        width:240px;
        margin: 0 20px;
       padding-bottom: 300px;
    }

    .middle {
        width: 730px;
        margin: 10px;
    }
</style>