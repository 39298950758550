<template>

  <div id="app">
<myheader/>



      <router-view

      />
    <myfooter/>

  </div>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import Vue from "vue";
    // eslint-disable-next-line no-unused-vars
    import styles from "./assets/css/main.css";
    // eslint-disable-next-line no-unused-vars
    import forms from "./assets/css/forms.css";
import myheader from './components/partials/myHeader'
    // eslint-disable-next-line no-unused-vars
    import animated from "./assets/css/animated.css";
import myfooter from './components/partials/myFooter'


export default {
  name: 'App',
  components: {
    myheader,

      myfooter
  },
  async created(){
      this.$router.replace({ name: "Co-Browsing-Sharing" });
      //this.$router.replace({ name: "Webinar-planning" });
  }
}
</script>

<style>
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}


.move-up-enter-active {
    animation:lightSpeedInRight 1s ease-in-out;
}

 </style>
