<template>
    <div class="navigation">
        <div class="wrapper">
        <div class="left">
            Video & Co-browsing
        </div>
            <div class="middle">
                Customer & Engagement Details
            </div>
            <div class="right">
                Manage audio & video streams
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "navigator"
    }
</script>

<style scoped>

.navigation{
    margin-top:40px;
padding-top: 4px;
width: 100vw;
    height: 66px;

}
.navigation .wrapper
{

    width: 650px;
    margin: 11px auto;
    padding: 0px;
    position: relative;
    height: 40px;


}

    .navigation .left{
        border: 1px solid #CBC2BA;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        float: left;

        font-size: 12px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 25px;
        background: #FFFFFF;

        padding-top: 6px;
        padding-bottom: 7px;
        width: 33%;
        min-width: 215px;
    }
.navigation .right{
    border: 1px solid #CBC2BA;
    border-left: 0px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 33%;
    min-width: 215px;
    padding-top: 6px;
    padding-bottom: 7px;
    float: left;

    font-size: 12px;
    color: #9E968F;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
    background: #F6F3F2;

}


.navigation .middle{
    border: 1px solid #CBC2BA;
    border-left: 0px;

    width: 33%;
    min-width: 215px;
    padding-top: 6px;
    padding-bottom: 7px;
    float: left;

    font-size: 12px;
    color: #9E968F;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
    background: #F6F3F2;

}

    .navigation .left:hover{

    background-color: #ECF4FE;
    cursor: pointer;
    }
.navigation .middle:hover{

    background-color: #ECF4FE;
    cursor: pointer;
}
.navigation .right:hover{

    background-color: #ECF4FE;
    cursor: pointer;
}
</style>