
<template>
    <div>

        <draggableCard :X="X" :Y="Y" v-show="show_bigchat" @close="click_on_bigchat"
                       class="draggable_card_wrapper" :class="maximized">
            <template v-slot:main>
                <!-- The code below goes into the header slot -->

                <div style="display: flex;">
                    <div class="inchat_video_wrapper" :class="maximized" >

                 <model-obj
width="825"
height="450"
                                backgroundAlpha="0.3"
                                :rotation="rotation"
                                src="x.obj"
                                mtl="x.mtl"
                        ></model-obj>

                        <div class="inchat_video_buttons" :class="maximized" >

                            <img class="image_button" src="../assets/icons/visitor_chat/settings.svg">
                            <img class="image_button" src="../assets/icons/visitor_chat/message.svg">


                            <img class="image_button distantiator_img"   src="../assets/icons/visitor_chat/audio.svg">
                            <img class="image_button" src="../assets/icons/visitor_chat/microphone.svg">
                            <img class="image_button" src="../assets/icons/visitor_chat/video.svg">
                            <img class="image_button" src="../assets/icons/visitor_chat/fullscreen.svg" >

                        </div>

                    </div>
                    <div class="chat_wrapper"
                         :class="maximized" >
                        <div class="big_messages" id='bigmessageslist'>
                            <div class="message"  v-for="message in messages" :key="message.message">

                                <div class="from">{{message.from}}</div>
                                <div class="time" >{{message.time}}</div>
                                <div class="text" :class="(message.from=='Marcus'?'sent':'received')">

                                    <p >{{message.text}}</p></div>

                            </div>
                        </div>
                        <div class="send_messages" >
                            <input type="text" v-model="message" placeholder="Type a message…" style="width: 240px;"/>
                            <button class="attach"></button>
                            <button class="send" v-on:click="send_message()"></button>
                        </div>

                    </div>
                </div>
            </template>
            <template v-slot:footer>
            </template>
        </draggableCard>

        <draggableCard X="100" Y="240" v-show="show_mini" @close="click_on_mini">
            <template v-slot:main>
                <!-- The code below goes into the header slot -->
                <video class="inchat_video2"
                       ref="video2"
                       id="video2"

                       src="https://hyundai.stonesoupdev.com/film1.mp4"

                ></video>
                <div style="width:280px">

                    <img class="image_button" src="../assets/icons/visitor_chat/settings.svg">
                    <img class="image_button" src="../assets/icons/visitor_chat/message.svg">


                    <img class="image_button" style="margin-left:70px;" src="../assets/icons/visitor_chat/audio.svg">
                    <img class="image_button" src="../assets/icons/visitor_chat/microphone.svg">
                    <img class="image_button" src="../assets/icons/visitor_chat/video.svg">
                    <img class="image_button" src="../assets/icons/visitor_chat/fullscreen.svg" @click="click_on_mini">

                </div>
            </template>
            <template v-slot:footer>
                <div class="messages" id='messageslist'>
                    <div class="message"  v-for="message in messages" :key="message.message">

                        <div class="from">{{message.from}}</div>
                        <div class="time" >{{message.time}}</div>
                        <div class="text" :class="(message.from=='Marcus'?'sent':'received')">

                            <p >{{message.text}}</p></div>

                    </div>
                </div>
                <div class="send_messages">
                    <input type="text" v-model="message" placeholder="Type a message…" style="width: 240px;"/>
                    <button class="attach"></button>
                    <button class="send" v-on:click="send_message()"></button>
                </div>
            </template>
        </draggableCard>
        <draggableCard X="200" Y="385"  v-show="show_teaser" @close="click_on_teaser()">
            <template v-slot:main>
                <!-- The code below goes into the header slot -->
                <div class="teaser" ></div>
            </template>
            <template v-slot:footer>
                <div style="background: #ECF4FE; font-family: BasierSquare-Regular;
font-size: 12px;
color: #646464;
letter-spacing: 0;
line-height: 9px;height:30px;padding-top:10px;padding-left:10px">
                    17. Juli, 16:00 CET
                </div>
                <div style="
font-size: 16px;
color: #646464;
letter-spacing: 0;
width:318px;
line-height: 20px;padding:23px;">
                    <strong style="font-family: BasierSquare-SemiBold;">Start: 16:00 Uhr.</strong> Live-Presentation der Highlights des Tucson Advantage. Gerne Fragen per Chat stellen!

                </div>
            </template>
        </draggableCard>
        <iframe src="https://www.hyundai.de/" class="hyunday_iframe"/>






    </div>



</template>

<script>
    import draggableCard from "@/components/cards/draggableCard";
    import { ModelObj } from 'vue-3d-model'

    export default {
        name: "ddd",
        components: {draggableCard,ModelObj},
        data() {
            return{
                rotation: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                X:100,
                Y:485,
                maximized:'',
                show_mini:false,
                show_teaser:false,
                show_bigchat:false,
                messages:[
                    {time:"10:24",
                        text:"Hallo",
                        from:"Ralph"},
                    {time:"10:25",
                        text:"Hallo, ich bin Markus",
                        from:"Marcus"}

                ],
                bot_messages:[
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?",
                    "Hallo, ich bin Markus",
                    "Wie kann ich Ihnen heute helfen?"
                ]
            }
        },



        methods:{


            make_full_screen:function(){
                this.maximized=(this.maximized==''?'maximized':'');

                if (this.maximized=='maximized'){
                    this.X=0;
                    this.Y=0;
                    this.$refs.draggableContainer.style.top=this.X+ 'px'
                    this.$refs.draggableContainer.style.left=this.Y+ 'px'
                }
            },
            click_on_teaser:function () {
                this.show_teaser=false;
                this.show_bigchat=false;
                this.show_mini=true;
                this.$refs.video.pause();
                this.$refs.video2.play();
            },
            click_on_mini:function () {
                this.show_mini=false;
                this.show_teaser=false;
                this.show_bigchat=true;
               // this.$refs.video.play();
               // this.$refs.video2.pause();



                this.rotate()
                this.$forceUpdate();
            },
            rotate () {
                this.rotation.y += 0.01;
                requestAnimationFrame( this.rotate );
            },
            click_on_bigchat:function () {
                this.show_teaser=true;
                this.show_mini=false;
                this.show_bigchat=false;
                this.$refs.video2.pause();
                this.$refs.video.pause();
            }
            ,
            send_message:function () {

                this.updateMessageArray(this.message,"Marcus")

                this.message="";
                setTimeout(()=>{

                    this.updateMessageArray(this.bot_messages[Math.floor((Math.random()*10) + 1)],"Ralph")

                },(Math.random()*1000+2000));

                this.$nextTick(() => {
                    var objDiv = document.getElementById("messageslist");
                    objDiv.scrollTop=9999999;
                    objDiv = document.getElementById("bigmessageslist")

                    objDiv.scrollTop=9999999;

                })
            }
            ,updateMessageArray(newMessage,fromWho) {

                this.messages.push({time:"10:24",
                    text:newMessage,
                    from:fromWho})
                this.$nextTick(() => {
                    var objDiv = document.getElementById("messageslist");
                    objDiv.scrollTop=9999999;
                    objDiv = document.getElementById("bigmessageslist")

                    objDiv.scrollTop=9999999;
                })
            }
        },
        mounted: function () {

            this.click_on_mini()


        },
    }
</script>
<style scoped>

    .hyunday_iframe{
        width: 100%;
        height:100vh;
        margin:0 auto;
        transform: translateY(-2px);
    }
    .minichat{
        background: no-repeat;
        background-image: url("../assets/static/fakeminichat.png");

        /*position: absolute;
            top:40px;
             left:100px;*/
        height: 500px;
        width: 300px;
    }
    .teaser{
        background:  url("../assets/static/transparent play.png") no-repeat center center,url("../assets/static/blue_car.png") no-repeat 12px 0px ;

        /* position: absolute;
          top:200px;
        left:685px;*/
        height: 214px;
        width: 362px;
    }

    .inchat_video{
        margin:0px 10px ;

        width: calc(100% - 20px);
    }
    .inchat_video2{

        margin:0px 10px ;


        width:260px;
    }
    .image_button {
        opacity: 0.4;
        cursor: pointer;
        margin:10px
    }
    .image_button:hover{
        opacity: 1;
    }
    .big_messages{
        margin-top:20px;
        margin-left:5px;
        height: 416px;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .messages{
        margin-left:5px;
        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .send_messages{
        height:56px;
        width:100%;
        background-color: #F8F8F8;
        border-top: 1px solid #DCDCDC;
        border-bottom-right-radius:20px;
        border-bottom-left-radius:20px;
    }

    .send {
        margin: 0 auto;



        display: inline;
        color: white;
        order: 1;

        transition: opacity .25s ease-out;
        width: 24px;
        height: 24px;
        border:0px;

        background:url("../assets/icons/chat/send.svg") no-repeat center;
        transform: translate(-30px,14px);
        position: absolute;
    }
    .attach {
        position: absolute;
        transform: translate(-55px,14px);
        display: inline;
        color: white;
        order: 1;

        transition: opacity .25s ease-out;
        width: 24px;
        height: 24px;
        border:0px;
        margin: 0 auto;
        background:url("../assets/icons/chat/attach.svg") no-repeat center;
    }
    .from{
        padding-left:18px;
        float:left;
        font-size: 8px;
        color: #989898;
        letter-spacing: 0;
        line-height: 22px;
        height:20px;
        width:30%;
    }

    .sent p{
        margin-left: 10px;
        line-height: 30px;

    }
    .received p{
        margin-left: 27px;
        line-height: 30px;

    }
    .time{
        line-height: 22px;
        padding-right:18px;
        float:left;
        text-align: right;
        font-size: 8px;
        color: #989898;
        letter-spacing: 0;

        height:20px;
        width:50%;
    }

    .text{
        float: left;
        width: 100%;
        padding-left: 20px;
        font-size: 10px;
        color: #000000;
        letter-spacing: 0;
        line-height: 16px;


    }

    .received{
        height: 50px;
        background: url("../assets/icons/chat/reply_arrow.svg") no-repeat 210px  center,url("../assets/icons/chat/maximized_message_received.svg") no-repeat 30px,url("../assets/icons/chat/lucutzmic1.png") no-repeat 2%  bottom;

        margin-right:10px;

    }


    .sent{
        padding: 0;
        height: 60px;
        margin-left:30px;
        background:url("../assets/icons/chat/message_sent.svg") no-repeat left,url("../assets/icons/chat/lucutzmic2.png") no-repeat 83%  bottom  ;

    }
    .message {
    }


    .inchat_video_wrapper{
        width:836px;
        height:90%;
    }
    .inchat_video_wrapper.maximized {
        width: calc(100vw - 273px);
        height: 100vh;
    }
    .inchat_video_wrapper.maximized video{
        height: calc(100% - 80px);
    }

    .inchat_video.maximized{
        height: calc(100% - 80px);
    }

    .inchat_video_buttons{
        width:820px;
    }
    .inchat_video_buttons.maximized {
        width:100vw;
    }
    .chat_wrapper{
        width:273px;background: white;   border-right: 1px solid #DCDCDC;
    }
    .draggable_card_wrapper{
        width:1096px;box-shadow: 0 10px 10px 0 rgba(0,0,0,0.10);
    }
    .draggable_card_wrapper.maximized   {
        width: calc(100vw - 273px);
        top:0px !important;
        left:0px !important;
    }

    .distantiator_img{
        margin-left:610px;
    }
    .maximized .distantiator_img {
        margin-left: calc(100% - 482px);
    }
    .maximized .big_messages {
        height: calc(100vh - 107px);
        width: 273px;
    }
    .chat_wrapper.maximized{
        margin:0;
        width: 100%;
    }

</style>