<template>
    <div class="logo">
        <img class="logo_img" src="../../assets/icons/logo.png">
    <div class="logo_text"> Online Showroom / Hyundai i20 Advantage Presentation</div>
<div class="starting_in" v-if="currentRoute==='/Co-Browsing-Sharing'">Starting in</div>
        <div v-if="currentRoute==='/Co-Browsing-Sharing'" class="starting_in_rectangle">5 days</div>
    </div>
</template>

<script>
    export default {
        name: "logo",
     data() {
        return {
            show_starting_in:false,
        }},
        computed: {
            currentRoute() {

                return this.$route.fullPath;
            }
        }
    }
</script>

<style scoped>
    .logo {
        float: left;
    }
    .logo_img {
        float: left;
        margin-top: 11px;
        margin-bottom: 9px;
        margin-left: 24px;
    }

    .logo_text{
        margin: 8px 11.5px;
        padding-left: 13.5px;
        float: left;
        height: 24px;
        min-width: 150px;
        border-left: 1px solid #989898;


        font-size: 18px;
        color: #002B5F;
        letter-spacing: 0;
        line-height: 25px;
    }
    .starting_in{
        margin: 8px 11.5px;
        float: left;
        color: #646464;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 25px;
    }
    .starting_in_rectangle{
        margin: 9px 11.5px;
        float: left;
padding:2px 10px;


        color: #FFFFFF;
        font-family: "Basier Square";
        font-size: 14px;
        letter-spacing: 0;

        text-align: center;

         height: 18px;
         border-radius: 20px;
        background-color: #8BD121;

    }
</style>